@font-face {
  font-family: "IBM_Plex";
  src: url("../fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: IBM_Plex;

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {
    font-size: 14px;
  }
}
