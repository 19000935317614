.contest_steps {
  padding: 12px;
  .chip {
    overflow:auto;
    touch-action: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
  .MuiChip-clickable.active {
    background-color: rgba(243, 112, 33, 0.2);
    color: $orange_mills_regular;
    border: 1px solid $orange_mills_regular;
  }
}

.contest_step_two {
  padding: 12px 16px;
  margin-bottom: 75px;

  .question_box {
    margin-top: 16px;

    .MuiChip-icon { color: #f37021 }
    .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
    .MuiChip-clickable.active {
      background-color: rgba(243, 112, 33, 0.2);
      color: $orange_mills_regular;
      border: 1px solid $orange_mills_regular;
    }

    @media only screen and (max-width: 1200px) {
      margin-bottom: 130px;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 16px;
  }
}

.contest_step_three {
  margin-top: 28px;
  margin-bottom: 85px;


  .step_three__container {
    padding-bottom: 12%;
    .step_three__button {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .__input {
        flex-grow: 1;
        display: flex;
        gap: 16px;
      }
    }

    .file_uploaded {
      padding: 0px 0px 0px 16px;
      margin-top: 12px;
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      .more_actions {
        position: relative;
        flex-basis: 80%;
        flex-grow: 2;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 16px;
  }
}

.contest_step_four {
  margin-top: 22px;
  margin-bottom: 140px;


  @media only screen and (max-width: 1200px) {
    padding: 0 16px;
  }
}

.descontoText{
  color: #f37021;
}