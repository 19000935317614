.dashboard-equipments {
  position: relative;
  width: 100%;
  .equipments_container {
    padding: 0 16px;
    padding-bottom: 58px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .dashboard-equipments-list_container {
    margin-bottom: 46px;
    padding: 28px 0 16px 18px;

    .no-equipment_container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      height: 20vh;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

a {
  text-decoration: none;
  color: #000;
}
