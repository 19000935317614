/* ******COLORS*************/

// Primary
$orange_mills_regular: #f37021;
$orange_mills_dark: #c24003;
$orange_mills_light: #ffa151;

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $orange_mills_regular;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($orange_mills_regular, 10%);
}

div.MuiDialog-paper {
  height: 70% ;
  align-self: flex-end;
  width: 50%;

  @media only screen and (max-width: 1200px){
    width: 100%;
  }
}