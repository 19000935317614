.reset_container {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2);

  @media only screen and (min-width: 1200px) {
    width: 34%;
  }

  @media only screen and (min-width: 1400px) {
    width: 25%;
  }

  .reset-stepper {
    @media only screen and (min-width: 1200px) {
      width: auto;
    }
  }
}
