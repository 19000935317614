.finance-home {
  width: 100%;
  padding-bottom: 80px;
  position: relative;

  .filter-section {
    padding: 16px 16px 0 16px;

    .status-chips {
      .MuiChip-icon {
        color: #f37021;
      }
      .MuiChip-clickable {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      .MuiChip-clickable.active {
        background-color: rgba(243, 112, 33, 0.2);
        color: $orange_mills_regular;
        border: 1px solid $orange_mills_regular;
      }

      @media only screen and (max-width: 1200px) {
      }
    }

    .filter-dropdown {
      box-sizing: border-box;
    }
  }

  .finance-content {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding-left: 16px;
    margin-top: 16px;

    .finance-empty {
      padding: 16px;
      text-align: center;
    }
  }

  .message_container {
    position: absolute;
    bottom: 0;

    @media only screen and (max-width: 1200px) {
      position: relative;
    }

    .message_content {
      max-width: 80%;
      font-size: 0.875em;
      text-align: center;
      padding: 8px;

      @media only screen and (max-width: 900px) {
        max-width: 100%;
      }
    }
  }
}
