.img-badge {
  & .MuiBadge-badge {
    top: 50;
    left: 20;
    background-color: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: none; // add for next iteration
  }
}

.close-right-menu {
  left: 0px;
  bottom: 0px;
  height: 56px;
  background: rgb(243, 112, 33);
  width: 100%;
  position: absolute;
  display: none;
  color: #fff;
  align-items: center;
  padding-left: 16px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  justify-content: flex-end;

  @media (max-width: 1200px) {
    display: flex;
  }
}

.buttons{
  color: #F37021;
  width: fit-content;
  font-size: larger;
  cursor: pointer;
  letter-spacing: 1.5px;
}

.buttons:hover{
  color:darkorange
}

.buttons:active{
  color:gray
}

.buttonWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}