.recover-success {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recover-success-message {
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 17px;

  .__title {
    margin-top: 52px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    font-feature-settings: "ss04" on, "liga" off;

    /* 01. Primary/500 */

    color: #f37021;
  }

  .__message {
    margin-bottom: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    font-feature-settings: "ss04" on;

    /* 00. On Surface / Medium Emphasis */

    color: rgba(0, 0, 0, 0.6);
  }
}

.success-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  .recover-button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* identical to box height, or 114% */

    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-feature-settings: "ss04";
  }
}
