.recover__container {
  .__account-recover {
    padding: 0 16px;
    margin-top: 50px;
    width: 100%;
    height: auto;

    .__headline {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      font-feature-settings: "ss04" on;
      color: rgba(0, 0, 0, 0.87);
    }

    .__message-body {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      font-feature-settings: "ss04" on, "liga" off;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .__recover-method {
    padding: 0 16px;
    margin-top: 32px;
    width: 100%;
    position: relative;

    .__headline {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      letter-spacing: 0.15px;
      font-feature-settings: "ss04" on, "liga" off;

      /* 00. On Surface / High Emphasis */

      color: rgba(0, 0, 0, 0.87);
    }

    .__recover-choice {
      display: flex;
      gap: 8px;
      
      .chip {
        background-color: rgba(33, 33, 33, 0.08);
      }

     .active {
        background-color: rgba(243, 112, 33, 0.2) ;
        color: $orange_mills_regular;
        border-color: $orange_mills_regular;
      }
    }
  }
}
