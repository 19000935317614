.register-doc__container {
  .__message-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .__message-commom {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* display: flex; */
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.25px;
      font-feature-settings: "ss04";
      margin: 6px;
    }

    .__message {
      text-align: center;
      margin-bottom: 64px;

      .__headline {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15px;
        font-feature-settings: "'ss04' on; 'liga' off";
        color: #f37021;
      }
    }
  }
}
