.register-first {
  position: relative;
  width: 100%;
  .__person-box {
    padding: 0 16px;

    .active {
      background-color: rgba(243, 112, 33, 0.2);
      color: $orange_mills_regular;
      border-color: $orange_mills_regular;
    }
  }
  .register-transition {
    position: absolute;
    animation: registerTransition 5s ease-in-out both;
    width: 100vw;
    height: 88vh;
    top: -23px;
    z-index: 800;
    background-color: $orange_mills_regular;
  }
}
