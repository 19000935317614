.email-recover {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

.check-icon,
.sync-icon {
  position: absolute;
  top: 100px;
  right: 25px;
}

.sync-icon {
  animation: iconRotation 1.5s ease-in-out forwards infinite;
}
