.dashboard-home__container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;

  .transition-skeleton-home {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .initial-screen {
    padding: 0 18px;
  }
}

@media only screen and (min-width: 1200px) {
  // default behavior modifying

  // menu
  .css-18sg6k4-MuiPaper-root-MuiDrawer-paper,
  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
  .css-1ciwpa8,
  .css-1l8j5k8 {
    display: contents !important;
  }

  div.css-1utbzmx {
    width: 100%;
  }

  .desktop-profile-menu,
  .desktop-menu {
    border-top: 0;
    border-bottom: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    position: relative;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .desktop-menu {
    border-right-width: thin;
  }

  .desktop-profile-menu {
    border-right: none;
    border-left-width: thin;
  }
}
