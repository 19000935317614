.questionary__container {
  padding: 16px 16px 16px 0px;
  margin-left: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .options {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .MuiChip-clickable.active {
    background-color: rgba(243, 112, 33, 0.2);
    color: $orange_mills_regular;
    border-color: $orange_mills_regular;
  }

  &:last-child {
    margin-bottom: 116px;
  }
}
