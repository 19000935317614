.suspense-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

div.suspense-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f37021;
  font-size: 1.2em;
  color: #fff;
  opacity: 0;
}

svg.suspense-icon {
  color: #fff;
  font-size: 8em;
  opacity: 0.5;
  animation-name: iconRotation;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.suspense-text {
  max-width: 400px;
  opacity: 0.8;
  text-align: center;
}
