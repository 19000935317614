.technical_step_one {
  padding: 0 16px;
  margin-bottom: 130px;
}

.technical_step_two {

  .step_two_button_box {
    display: flex;
    justify-content: flex-end;
    .step_two__button {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
  
      .__input {
        flex-grow: 1;
        display: flex;
        gap: 16px;
      }
    }
  }
  

  .file_uploaded {
    padding: 0px 0px 0px 16px;
    margin-top: 64px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .file_wrapper{
      display: flex;
      width: 100%;
      padding-bottom: 2px;
    }

    

    .more_actions {
      position: relative;
      flex-basis: 80%;
      flex-grow: 2;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  
  .comment_box {
    padding: 8px 16px;
  }

  .midia_attache_box {
    padding: 0 16px;
    display: flex;
    justify-content: flex-end;
  }
}

.technical_step_three {
  padding: 12px 16px;

  .question_box {
    margin-top: 24px;

    .MuiChip-icon { color: #f37021 }
    .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
    .MuiChip-clickable.active {
      background-color: rgba(243, 112, 33, 0.2);
      color: $orange_mills_regular;
      border: 1px solid $orange_mills_regular;
    }
  }
}

.technical_step_four {
  .address_box {
    padding: 8px 16px;

    .MuiChip-icon { color: #f37021 }
    .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
    .MuiChip-clickable.active {
      background-color: rgba(243, 112, 33, 0.2);
      color: $orange_mills_regular;
      border: 1px solid $orange_mills_regular;
    }
  }
}