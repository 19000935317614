.dashboard-welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  .title {
    height: 20px;
    /* Subtitle 1 */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    font-feature-settings: "ss04" on, "liga" off;
    margin-bottom: 0;

    /* 01. Primary/500 */
    color: #f37021;
  }

  .body {
    /* Body 2 */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    font-feature-settings: "ss04" on;
    margin-bottom: 0;

    /* 00. On Surface / Medium Emphasis */
    color: rgba(0, 0, 0, 0.6);
  }
}
