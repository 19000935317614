.preschedule_devolution_container {
  width: 100%;
  position: relative;

  .navbar {
    padding: 0;
  }

  .filter_field {
    padding: 0 0px;
    padding: 58px;

    @media only screen and (max-width: 1200px) {
      padding: 0 16px;
    }
  }

  .equipment {
    .stepper__card {
      background-color: lighten($orange_mills_regular, 35);
      border-radius: 0;
    }
  }

  .steps {
    .step_one__container {
      .step_one__button {
        padding: 32px 16px 0 16px;
        display: flex;
        justify-content: center;

        .__input {
          flex-grow: 1;
          display: flex;
          gap: 16px;
        }
      }

      .file_uploaded {
        padding: 0px 0px 0px 16px;
        margin-top: 64px;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        .more_actions {
          position: relative;
          flex-basis: 80%;
          flex-grow: 2;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }

    .step_two__container {
      padding: 32px 16px;

      .question_box {
        margin-top: 24px;

      .MuiChip-icon { color: #f37021 }
      .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
      .MuiChip-clickable.active {
        background-color: rgba(243, 112, 33, 0.2);
        color: $orange_mills_regular;
        border: 1px solid $orange_mills_regular;
      }
      }
    }

    .step_three {
      padding: 32px 16px;
    }

    .step_four {
      padding: 16px 0px 16px 0px;
    }

    .step_five {
      .comment_box {
        padding: 8px 16px;

        &:last-child {
          margin-bottom: 116px;
        }
      }
    }

    .step_six {
      .address_box {
        padding: 8px 16px;

      .MuiChip-icon { color: #f37021 }
      .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
      .MuiChip-clickable.active {
        background-color: rgba(243, 112, 33, 0.2);
        color: $orange_mills_regular;
        border: 1px solid $orange_mills_regular;
      }
      }
    }
  }

  .stepper {
    position: fixed;
    bottom: 56px;
    width: 100%;
    z-index: 100;

    // @media only screen and (min-width: 600px) {
    //   bottom: 64px;
    // }

    @media only screen and (min-width: 1200px) {
      position: absolute;

      left: 0;
      bottom: 0;
    }
  }
}
