.logo-message {
  color: $orange_mills_regular;
  height: 16px;
  left: calc(50% - 328px / 2);
  top: 164px;

  /* Headline 6 */

  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 24px;
  /* or 120% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  font-feature-settings: "ss04" on;
}
