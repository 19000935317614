.help-text {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  font-family: IBM_Plex;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
