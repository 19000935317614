.register-password {
  padding: 0 18px;

  .password-requirements__container {
    margin-left: 18px;
    display: flex;
    flex-wrap: wrap;

    div {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      position: relative;

      .requirements {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        letter-spacing: 0.4px;
        font-feature-settings: "ss04" on;

        color: rgba(0, 0, 0, 0.6);
      }

      .check-1,
      .check-2,
      .check-3,
      .check-4,
      .check-5 {
        position: absolute;
      }

      .check-1,
      .check-2 {
        left: 100px;
      }
      .check-3,
      .check-4,
      .check-5 {
        left: 135px;
      }
    }

    span {
      flex-basis: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-feature-settings: "ss04" on;
    }
  }
}
