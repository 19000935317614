.home-create-access {
  min-height: 100vh;
  max-width: 320px;
  flex-direction: column;
  gap: 128px;

  .access__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 26px;
  
    .btn-create-access {
      width: 182px;
      height: 48px;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
        0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
      position: relative;
  
      span {
        position: absolute;
        height: 16px;
        left: 48px;
        right: 19px;
        top: calc(50% - 16px / 2);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
  
        letter-spacing: 1.25px;
        text-transform: uppercase;
        font-feature-settings: "ss04";
      }
  
      svg {
        position: absolute;
        left: 10px;
        top: 22%;
        bottom: 20.83%;
      }
    }
  
    .btn-login-access {
      /* ✏️ Label */
      transition: 1s;
      text-decoration: none;
      height: 16px;
  
      /* Button */
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      font-feature-settings: "ss04" on;
  
      /* 01. Primary/500 */
      color: $orange_mills_regular;
  
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      z-index: 1;
  
      &:hover {
        color: darken($orange_mills_regular, 20%) ;
      }
    }
  
  }
}
