// Keyframes --------------------------------

//*** Home Page

// logo animation
@keyframes enterHomeBackgroundBars {
  0% {
    opacity: 1;
    height: 0%;
  }

  35% {
    height: 100%;
  }

  45% {
    height: 35%;
  }

  75% {
    opacity: 0;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes enterHomeAnimationSoft {
  0% {
    height: 0;
    opacity: 1;
  }

  75% {
    height: 100%;
  }

  100% {
    opacity: 0;
  }
}

@keyframes enterHomeBackgroundDark {
  0% {
    background-color: $orange_mills_dark;
    height: 100%;
    display: initial;
  }

  50% {
    background-color: $orange_mills_regular;
  }

  75% {
    opacity: 1;
  }

  99% {
    height: 100%;
  }

  100% {
    background-color: #ffffff;
    height: 0;
    opacity: 0;
  }
}

// Logo animation - bars
@include strechedBar(One, 66px, 89px);
@include strechedBar(Two, 58px, 97px);
@include strechedBar(Three, 58px, 97px);
@include strechedBar(Four, 98px, 57px);
@include strechedBar(Five, 82px, 73px);
@include strechedBar(Six, 82px, 73px);

// icon ratation
@keyframes iconRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//*** Register page

@keyframes registerTransition {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    height: 88vh;
  }
  100% {
    height: 0;
  }
}

@keyframes registerLogoTransitions {
}

// Animaiton classes ------------------------
.enterAnimation {
  position: relative;

  .enter-home__animation {
    animation: enterHomeAnimationSoft 1.2s ease-in forwards 1;
    position: absolute;
    z-index: 999;
    top: 0;
    height: 0;
    width: 100%;
    background-color: $orange_mills_regular;

    .__bars-animation {
      animation: enterHomeBackgroundBars 1.8s ease-in forwards 1;

      width: 100%;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .bar-1 {
        @include bars(66px, 69px, 89px, $orange_mills_dark, barOne, -61px, 5px);
      }
      .bar-2 {
        @include bars(58px, 105px, 97px, $orange_mills_dark, barTwo, 0px, 7px);
      }

      .bar-3 {
        @include bars(
          58px,
          140px,
          97px,
          $orange_mills_dark,
          barThree,
          0px,
          3px
        );
      }
      .bar-4 {
        @include bars(98px, 169px, 57px, $orange_mills_dark, barFour, 0px, 0px);
      }
      .bar-5 {
        @include bars(82px, 198px, 73px, $orange_mills_dark, barFive, 0px, 0px);
      }
      .bar-6 {
        @include bars(82px, 226px, 73px, $orange_mills_dark, barSix, 0px, 0px);
      }
    }
  }

  .enter-home__animation-bg {
    height: 0;
    width: 100%;
    position: absolute;

    z-index: 998;
    top: 0;
    animation: enterHomeBackgroundDark 1.2s ease-in forwards 1;
  }
}

@keyframes logoMovimentM {
  0% {
    opacity: 0.3;
    display: block;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes logoMovimentDot {
  0% {
    transform: translate(0px, 0px);
    opacity: 0.3;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    transform: translate(0px, -110px);
    opacity: 1;
  }
}

.logo-moviment__container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f37021;
  z-index: -1;
  top: -100px;
  left: 0;
  position: absolute;
  transition: 0.5s;

  .logo-m {
    animation: logoMovimentM 0.5s ease-in alternate 4;
  }

  .logo-dot {
    animation: logoMovimentDot 0.5s ease-in alternate 4;
  }
}
