.ticket_technical {
  margin-bottom: 80px;

  .technical-foating--button {
    position: fixed;
    right: 28%;
    bottom: 6%;

    @media only screen and (max-width: 1200px) {
      right: 10%;
      bottom: 10%;
    }
  }

  .field_filter {
    padding: 0 0px;
    padding-bottom: 58px;

    @media only screen and (max-width: 1200px) {
      padding: 0 16px;
    }
  }

  .status_chips {
    .MuiChip-icon { color: #f37021 }
    .MuiChip-clickable { border: 1px solid rgba(0,0,0,0.1); }
    .MuiChip-clickable.active {
      background-color: rgba(243, 112, 33, 0.2);
      color: $orange_mills_regular;
      border: 1px solid $orange_mills_regular;
    }
    
    @media only screen and (max-width: 1200px) {
      padding: 16px 16px 0 16px;
      margin-top: 16px;
    }
  }

  .technical_equipmen_list {
    margin-bottom: 46px;
    padding: 28px 0 16px 18px;
  }
}