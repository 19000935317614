.calling_devolution {
  .chip__container {
    padding: 28px;

    div {
      margin-right: 8px;
    }

    svg {
      color: $orange_mills_regular;
    }

    .active {
      background-color: rgba(243, 112, 33, 0.2);
      color: $orange_mills_regular;
      border-color: $orange_mills_regular;
    }
  }

  .calling_filter {
    padding: 0 16px;
    padding-bottom: 58px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    .calling_results {
      margin-top: 42px;
    }
  }

  .calling_proposal_list {
    margin-bottom: 46px;
    padding: 28px 0 16px 18px;
  }
}
