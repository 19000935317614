//*** Home - Bars logo animation - Keyframe
@mixin strechedBar($name, $finalHeight, $top) {
  @keyframes bar#{$name} {
    0% {
      height: 100%;
      opacity: 1;
      background-color: $orange_mills_light;
      top: 0;
    }

    50% {
      height: 100%;
      opacity: 1;
      background-color: $orange_mills_regular;
    }

    75% {
      top: $top;
      height: 50%;
      background-color: $orange_mills_dark;
    }

    85% {
      height: $finalHeight;
    }

    100% {
      height: $finalHeight;
    }
  }
}

@mixin bars($height, $left, $top, $bgColor, $barName, $mgLeft, $mgRight) {
  width: 16px;
  height: $height;
  left: $left;
  top: $top;
  background-color: $bgColor;
  animation: $barName 1s ease-in forwards;
  margin-left: $mgLeft;
  margin-right: $mgRight;
}

//*** Register
