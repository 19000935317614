.calling_home {
  width: 100%;

  .calling_delivery_copy__body {
    padding: 16px;
  }

  .delivery_copy__send {
    margin-top: 8px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 8px 16px 8px 0;
  }
}
