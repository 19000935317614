.home-login {
  min-height: 100vh;
  max-width: 320px;
  flex-direction: column;
  position: relative;

  .home-login__container {
    .title {
      width: 328px;
      height: 13px;

      /* Subtitle 1 */
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;
      font-feature-settings: "ss04" on, "liga" off;

      /* 00. On Surface / Medium Emphasis */
      color: rgba(0, 0, 0, 0.6);
    }

    .check-icon,
    .sync-icon {
      position: absolute;
      top: 278px;
    }

    .check-icon {
      color: #ffffff;
      background-color: #f37021;
      border-radius: 50%;
    }

    .sync-icon {
      animation: iconRotation 1.5s ease-in-out forwards infinite;
      border-radius: 50%;
    }

    .email-box {
      margin-top: 33px;
      margin-bottom: 67px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        align-self: end;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }

      a {
        align-self: end;
        text-decoration: none;
        color: $orange_mills_regular;
        padding: 8px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.25px;
        line-height: 16px;
        text-transform: uppercase;
        font-feature-settings: "ss04" on;
      }
    }
  }
}
