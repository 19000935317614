@import "@assets/scss/assets";
@import "@containers/containers";
@import "@components/components";
@import "@containers/Login/Register/components/views";
@import "@containers/Login/Recover/components/recover";
@import "@layout/layout";

/* Z-index layers specificaton
    1100+     -> popup
    800 - 999 -> Object animations
    1000 - 1099 -> Menus
*/
